var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"branding-wrapper"},[_c('SmbTitles',{attrs:{"is-show-title":"","is-show-sub-title":"","title":"Setup your branding","sub-title":"This is a one time setup to get fully branded content. You can always update it at a later time in Settings."}}),_c('SmbBranding',{attrs:{"is-show-colors-block":_vm.isShowColorsBlock,"is-show-images-block":_vm.isShowImagesBlock,"is-show-fonts-block":_vm.isShowFontsBlock,"is-show-hashtags-block":false,"is-show-values-block":false}}),_c('div',{staticClass:"footer"},[_c('SmbActionBtn',{attrs:{"text":"Done"},on:{"click":function($event){return _vm.$router.push({
          name:
            _vm.$route.params && _vm.$route.params.nameOfRedirectedPage
              ? _vm.$route.params.nameOfRedirectedPage
              : 'smb-client',
          params: { ..._vm.$route.params },
        })}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }