<template>
  <div class="branding-wrapper">
    <SmbTitles
      is-show-title
      is-show-sub-title
      title="Setup your branding"
      sub-title="This is a one time setup to get fully branded content. You can always update it at a later time in Settings."
    />
    <SmbBranding
      :is-show-colors-block="isShowColorsBlock"
      :is-show-images-block="isShowImagesBlock"
      :is-show-fonts-block="isShowFontsBlock"
      :is-show-hashtags-block="false"
      :is-show-values-block="false"
    />
    <div class="footer">
      <SmbActionBtn
        text="Done"
        @click="
          $router.push({
            name:
              $route.params && $route.params.nameOfRedirectedPage
                ? $route.params.nameOfRedirectedPage
                : 'smb-client',
            params: { ...$route.params },
          })
        "
      />
    </div>
  </div>
</template>

<script>
import SmbTitles from "@/components/smb-client/SmbTitles.vue";
import SmbBranding from "@/components/smb-client/SmbBranding.vue";
import SmbActionBtn from "@/components/smb-client/SmbActionBtn.vue";
import { UPDATE_BRANDING_TYPES } from "@/constants/smb-client";
export default {
  name: "Index",
  components: {
    SmbBranding,
    SmbTitles,
    SmbActionBtn,
  },
  data: () => ({
    isShowImagesBlock: true,
    isShowColorsBlock: true,
    isShowFontsBlock: true,
  }),
  created() {
    if (this.$route.params && this.$route.params.actionType) {
      let actionType = this.$route.params.actionType;
      if (actionType === UPDATE_BRANDING_TYPES.NEED_TO_UPDATE_LOGO_AND_COLORS) {
        this.isShowColorsBlock = true;
        this.isShowImagesBlock = true;
        this.isShowFontsBlock = true;
      } else if (actionType === UPDATE_BRANDING_TYPES.NEED_TO_UPDATE_LOGO) {
        this.isShowImagesBlock = true;
        this.isShowFontsBlock = true;
        this.isShowColorsBlock = false;
      } else {
        this.isShowColorsBlock = true;
        this.isShowFontsBlock = true;
        this.isShowImagesBlock = false;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.branding-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  ::v-deep {
    .smb-client-wrapper {
      padding: 50px 25px 25px;
      .container-item {
        &__title {
          font-size: 18px;
        }
        &__small-img {
          max-width: 80px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .logo-title {
            font-size: 14px;
          }
          .small-img-container {
            width: 60px;
            height: 60px;
            &__upload {
              position: relative;
              .icon-close {
                position: absolute;
                right: 10px;
                z-index: 10;
              }
              .v-input__prepend-outer {
                .icon {
                  font-size: 40px !important;
                  width: 60px;
                  height: 60px;
                }
              }

              &-text {
                margin-top: 10px;
                font-size: 13px;
              }
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
            i {
              cursor: pointer;
              position: absolute;
              background: #fff;
              padding: 2px;
              border-radius: 50%;
              display: inline-flex;
              font-size: 12px;
              &.icon-edit {
                left: 0;
              }
              &.icon-remove {
                right: 0;
              }
            }
          }
        }
      }
      .squares-container {
        .square {
          width: 60px;
          height: 60px;
          &__add {
            width: 60px;
            height: 60px;
            font-size: 30px;
          }
        }
      }
      .fonts-container {
        .logo-title {
          font-size: 15px;
        }
        .font {
          font-size: 15px;
        }
      }
    }
  }
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f2f3;
  @media screen and (min-width: 760px) {
    padding-bottom: 20px;
  }
  .button-item {
    width: 100%;
    @media screen and (min-width: 760px) {
      max-width: 315px;
    }
  }
}
</style>
